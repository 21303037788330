 <template>
  <list :title="$_t('requests')" class="wa__large">
    <hr class="mt-0" />
    <template>
      <v-row class="px-2 pb-1 d-none d-md-flex" v-if="type === 'manager'">
        <v-col cols="3" class="wa__request__title--title"
          ><span>{{ $_t("requester") }}</span></v-col
        >
        <v-col cols="3" class="wa__request__title--title pl-9"
          ><span>{{ $_t("model") }}</span></v-col
        >
        <v-col cols="3" class="wa__request__title--title pl-16"
          ><span class="pl-5">{{ $_t("dateAndStatus") }}</span></v-col
        >
        <v-col cols="3" class="wa__request__title--title text-right"
          ><span>{{ $_t("controls") }}</span></v-col
        >
      </v-row>
      <v-row class="px-2 pb-1 d-none d-md-flex" v-if="type === 'model'">
        <v-col cols="4" class="wa__request__title--title"
          ><span>{{ $_t("requester") }}</span>
        </v-col>
        <v-col cols="4" class="wa__request__title--title text-center"
          ><span>{{ $_t("dateAndStatus") }}</span>
        </v-col>
        <v-col cols="4" class="wa__request__title--title text-right"
          ><span>{{ $_t("controls") }}</span>
        </v-col>
      </v-row>
      <v-row class="px-2 pb-1 d-none d-md-flex" v-if="type === 'client'">
        <v-col cols="4" class="wa__request__title--title"
          ><span>{{ $_t("model") }}</span>
        </v-col>
        <v-col cols="4" class="wa__request__title--title text-center"
          ><span>{{ $_t("dateAndStatus") }}</span>
        </v-col>
        <v-col cols="4" class="wa__request__title--title text-right"
          ><span>{{ $_t("controls") }}</span>
        </v-col>
      </v-row>
      <data-iterator
        :items="getRequests"
        :is-filtered="isFilteredRequest"
        @pagination="changePagination"
        :pagination="getPaginationRequest"
        :loading="getRequestLoading"
        class="row mt-0 px-3 pb-4"
      >
        <template v-slot:loading>
          <loading />
        </template>
        <template #no-items>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
          <no-items textEn=" No Products Yet! " />
        </template>
        <template #no-results>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
          <no-items />
        </template>

        <template v-slot:items="{  item, itemNumber }">
          <RequestItem
            :key="item.id"
            :request="item"
            :index="itemNumber"
            v-if="type === 'manager'"
          />
          <RequestModelItem
            :key="item.id"
            :request="item"
            :index="itemNumber"
            v-if="type === 'model'"
          />
          <RequestClientItem
            :key="item.id"
            :request="item"
            :index="itemNumber"
            v-if="type === 'client'"
          />
        </template>
      </data-iterator>
    </template>
  </list>
</template>
<script>
import RequestItem from "./Item";
import RequestModelItem from "./ModelItem";
import RequestClientItem from "./ClientItem";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { UserStorage } from "@/utils/storage";

export default {
  components: {
    RequestItem,
    RequestModelItem,
    RequestClientItem,
  },
  data: () => ({
    type: UserStorage.get().type,
  }),
  computed: {
    ...mapGetters("request", [
      "getRequests",
      "getPaginationRequest",
      "isFilteredRequest",
      "getRequestLoading",
    ]),
  },
  methods: {
    ...mapActions("request", ["loadRequests"]),
    ...mapMutations("request", ["SET_PAGINATION"]),
    changePagination(page) {
      if (this.getPaginationRequest.page !== page) {
        this.SET_PAGINATION({ pagination: page });
        this.loadRequests();
      }
    },
  },
  created() {
    this.loadRequests();
  },
};
</script>
<style scoped>
.btn-clear {
  padding: 12px 8px !important;
  font-size: 11px;
}
.btn-execute {
  font-size: 12px;
  padding: 12px 31px !important;
  border-radius: 3px !important;
}
.wa__request__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>