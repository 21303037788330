<template>
  <basic-modal
    @open="openModal"
    width="700"
    transition="slide-x-transition"
    not-footer="true"
  >
    <template #header>
      <divider
        title="change status"
        sub-title="THE REQUEST STATUS"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-5 mt-3 pb-7">
        <status-request :items="getStatuses" v-model="form.status.id" />
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import StatusRequest from "../Global/Section/StatusRequest.vue";
export default {
  name: "modal_changeStatus",
  components: {
    StatusRequest,
  },
  methods: {
    openModal({ model }) {
      this.form = { ...model };
      this.pastStatusId = model.status.id;
    },
    ...mapActions("status", ["loadStatuses"]),
    ...mapActions("request", ["updateRequestStatus"]),
  },
  data: () => ({
    form: {},
    pastStatusId: null,
  }),
  computed: {
    ...mapGetters({
      getStatuses: "status/getStatuses",
    }),
  },
  watch: {
    form: {
      async handler(newForm) {
        try {
          if (newForm.status.id !== this.pastStatusId) {
            this.form.status.id = newForm.status.id;
            this.updateRequestStatus(this.form);
            this.$_closeModal();
          }
        } catch (e) {
          return e;
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadStatuses();
  },
};
</script>