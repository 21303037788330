<template>
  <v-form @submit.prevent="submit" class="h-100">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4" v-if="type !== 'client'">
          <v-text-field
            class="no-error-msg"
            :label="$_t('userName')"
            color="SonicSilver"
            v-model="filter.user_name.val"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="type !== 'model'">
          <v-text-field
            class="pt-0 no-error-msg"
            :label="$_t('modelName')"
            color="SonicSilver"
            v-model="filter.model_name.val"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            class="pt-0 no-error-msg"
            :items="getStatuses"
            item-value="id"
            item-text="name"
            :label="$_t('status')"
            color="SonicSilver"
            v-model="filter.status_id.val"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-2">
          <date-input
            class="no-error-msg"
            :label="$_t('dateAfter')"
            color="SonicSilver"
            v-model="filter.created_at.val1"
            outlined
            dense
          ></date-input>
        </v-col>
        <v-col cols="12" class="pt-2">
          <date-input
            class="mt-n1 no-error-msg"
            :label="$_t('dateBefore')"
            color="SonicSilver"
            v-model="filter.created_at.val2"
            outlined
            dense
          ></date-input>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            :text="$_t('execute')"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import { UserStorage } from "@/utils/storage";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
  },
  model_name: {
    type: "like",
    val: null,
  },
  user_name: {
    type: "like",
    val: null,
  },
  status_id: {
    type: "in",
    val: null,
  },
};
export default {
  data() {
    return {
      filter: {},
      type: UserStorage.get().type,
    };
  },
  computed: {
    ...mapGetters("status", ["getStatuses"]),
    ...mapGetters("request", ["getFiltersRequest"]),
  },
  methods: {
    ...mapActions("status", ["loadStatuses"]),
    ...mapMutations("request", ["SET_FILTER_REQUEST", "SET_IS_FILTERED_REQUEST"]),
    ...mapActions("request", ["loadRequests"]),
    submit() {
      this.SET_IS_FILTERED_REQUEST(true);
      this.SET_FILTER_REQUEST(this.filter);
      this.loadRequests();
    },
    clearFilter() {
      this.SET_IS_FILTERED_REQUEST(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_REQUEST(cloneDeep(this.filter));
      this.loadRequests();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersRequest });
    },
  },
  created() {
    this.loadStatuses();
    this.loadPastFilter();
  },
};
</script>
<style scoped>
.h-40 {
  height: 40px;
}
</style>
