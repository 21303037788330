<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col>
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__hiring__requests">
          <v-col cols="12" lg="2"><RequestFilter /></v-col>
          <v-col cols="12" lg="10"><RequestItems /></v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="tex-center">
      <ModalRequestDetails />
      <ModalClientDetails />
      <ModalStatus />
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex"
import RequestItems from "../components/Request/Items";
import RequestFilter from "../components/Request/Filter";
import ModalRequestDetails from "../components/Request/ModalRequest.vue";
import ModalClientDetails from "../components/Request/ModalClient.vue";
import ModalStatus from "../components/Request/ModalStatus.vue";
export default {
  components: {
    RequestItems,
    RequestFilter,
    ModalRequestDetails,
    ModalClientDetails,
    ModalStatus,
  },
  data() {
    return {
      login: true,
    };
  },
  methods: {
    ...mapMutations('request', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>