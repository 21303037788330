<template>
  <div class="wa__model__request mb-1">
    <v-row class="text-left">
      <v-col
        cols="6"
        md="3"
        class="wa__model__request__name"
        @click="$_openModal('requestDetails', { model: request })"
      >
        <name
          :number="index + 1"
          :title="request.user.name"
          :sub-title="request.user.email"
        />
      </v-col>
      <!--model secttion-->
      <!-- TODO: fix console bug -->
      <v-col
        cols="3"
        class="d-none d-md-flex wa__model__request__model pl-5 align-center"
      >
        <!--model name-->
        <div class="text-left">
          <div class="wa__model__request__name--title pl-6">
            {{ request.model.name }}
          </div>
          <!-- /model name-->
          <!-- model operation-->
          <div class="wa__model__request__name--operations pl-6">
            <block-button
              :height="!request.model.name ? 25 : 10"
              class="btn-operations"
              :class="{ 'btn-operations--only': !request.model.name }"
              :text="request.model.busyStatus.text"
              :color="request.model.busyStatus.color"
              :bg-color="request.model.busyStatus.bgColor"
            />
          </div>
        </div>
        <!-- /model operation-->
      </v-col>
      <!-- /model section-->
      <!-- date-status section-->
      <v-col cols="3" class="d-none d-md-block wa__model__request__date pl-5">
        <div class="pl-16 h-100">
          <div class="d-flex justify-center align-center h-100">
            <div class="date__status__box">
              <!-- /date button-->
              <block-button
                height="13"
                class="btn-date"
                color="gray"
                bg-color="bgGray"
                :text="request.created_at"
              />
              <!-- /date button-->
              <!-- status button-->
              <div>
                <block-button
                  height="auto"
                  class="btn-pending"
                  :text="request.status.name"
                  @click.native="
                    type === 'manager'
                      ? $_openModal('changeStatus', { model: request })
                      : () => {}
                  "
                  :color="request.status.style.color"
                  :bg-color="request.status.style.bgColor"
                ></block-button>
              </div>
              <!-- /status button-->
            </div>
          </div>
        </div>
      </v-col>
      <!-- /date-status section-->
      <!-- control section-->
      <v-col cols="6" md="3" class="d-flex justify-content-end align-items-center">
        <!-- align button-->
        <circle-button
          icon="WMi-align-left"
          color="black"
          @click.native="$_openModal('requestDetails', { model: request })"
        ></circle-button>
        <!-- /align button-->
        <!-- trash button-->
        <circle-button
          icon="WMi-trash"
          @click.native="deleteMessage"
          color="red"
          bg-color="bgLightPink"
        ></circle-button>
        <!-- /trash button-->
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
import Name from "../Global/Section/Name.vue";
import { mapActions } from "vuex";
import toast from "@/utils/toast";
import { UserStorage } from "@/utils/storage";
export default {
  components: {
    Name,
  },
  data() {
    return {
      type: UserStorage.get().type,
    };
  },
  methods: {
    openModal() {},
    ...mapActions("request", ["deleteRequest"]),
    deleteMessage() {
      toast.question(
          this.$_trans("toast.del_request_item_msg"),
          this.$_trans("toast.del_request_item_title"), () => {
        this.deleteRequest(this.request.id);
      });
    },
  },
  props: {
    request: {
      type: Object,
    },
    index: {},
  },
};
</script>
<style scoped>
.wa__model__request {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 7px 10px;
}

.wa__model__request__name {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.wa__model__request__name--title {
  font-family: "Montserrat-regular", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}
.btn-operations {
  font-size: 8px;
  position: relative;
  top: -0.6rem;
  cursor: default !important;
  padding: 3px 5px !important;
  border-radius: 1px !important;
}
.btn-operations--only {
  font-size: 16px;
  top: -0.4rem;
  padding: 3px 12px !important;
}
.date__status__box {
  line-height: 18px;
}
.btn-date {
  padding: 0 15px !important;
  font-size: 9px;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  cursor: default !important;
}
.btn-pending {
  font-family: "Montserrat-bold";
  font-size: 14px;
  font-weight: 900;
  padding: 1px 10px !important;
  border-radius: 1px !important;
}
.request__detail li {
  display: inline-flex;
  margin-right: 65px;
}
.request__detail li:nth-child(6) {
  margin-right: 140px;
}
</style>
