<template>
  <basic-modal @open="openModal" width="1100" transition="slide-x-transition">
    <template #header>
      <divider
        :title="$_t('requestDetail.title')"
        :sub-title="$_t('requestDetail.subTitle')"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 mb-11">
        <div class="mt-1 pt-5">
          <section-title title="REQUEST DATA"></section-title>
          <ul class="d-block d-md-flex pl-0 requester__modal__list mt-2">
            <request-details-information
              :title="$_t('requestDetail.dateBegin')"
              :subTitle="form.begin_date"
            />
            <request-details-information
              :title="$_t('requestDetail.requiredDays')"
              :subTitle="form.required_day"
            />
            <request-details-information
              :title="$_t('requestDetail.description')"
              :subTitle="form.description"
            />
            <request-details-information
              :title="$_t('requestDetail.location')"
              :subTitle="form.city"
            />
            <request-details-information
              :title="$_t('requestDetail.callMe')"
              :subTitle="form.call_me ? 'yes' : 'no'"
            />
            <request-details-information
              v-if="form.jobType"
              title="JOB TYPE"
              :subTitle="form.jobType ? form.jobType.name : ''"
            />
            <li v-else class="request__details__information">
              <div class="d-block ml-1">
                <div class="request__details__information--title">{{$_t('requestDetail.downloadFile')}}</div>
                <div
                  class="
                    request__details__information--sub-title
                    wa__tile--link
                  "
                >
                  <a :href="form.file" target="_blank" v-if="form.file">
                    download file
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <ul class="d-block d-md-flex pl-0 requester__modal__list mt-2">
            <li class="request__details__information">
              <div class="d-block ml-1">
                <div class="request__details__information--title">
                 {{$_t('requestDetail.modelTypes')}}
                </div>
                <div class="request__details__information--sub-title d-flex mt-1">
                  <v-chip
                    class="mr-1 request__details__information--sub-title--block"
                    color="#e6e6e673"
                    text-color="black"
                    label
                    x-small
                    v-for="modelType in form.modelTypes"
                    :key="modelType.id"
                  >
                    {{ modelType.name }}
                  </v-chip>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="userType !== 'client'">
          <section-title title="requester" />
          <ul
            class="
              d-flex
              justify-content-between
              list-unstyled
              pl-0
              requester__modal__list
              mt-2
            "
          >
            <request-details-information
              title="FULL NAME"
              :subTitle="
                userType === 'manager' ? form.user.name : form.user.first_name
              "
            />
            <request-details-information
              title="EMAIL ADDRESS"
              :subTitle="userType === 'manager' ? form.user.email : ''"
            />
            <request-details-information
              title="CORPORATE"
              :subTitle="form.user.corporate"
            />
            <request-details-information
              title="TOTAL REQUESTS"
              :subTitle="form.user.requestCount"
            />
            <li class="mt-1" v-if="userType === 'manager'">
              <block-button
                text="KNOW MORE"
                icon-right="WMi-right-open"
                class="btn-more site__button width-auto"
                @click.native="
                  $_openModal('clientDetails', { model: form.user })
                "
              ></block-button>
            </li>
          </ul>
        </div>
        <div v-if="userType !== 'model'">
          <section-title title="model" />
          <ul
            class="d-block d-md-flex pl-0 list-unstyled position-relative mt-2"
          >
            <request-details-information
              :title="$_t('requestDetail.fullName')"
              :subTitle="userType === 'manager' ? form.model.name : form.model.first_name"
            />
            <request-details-information
              :title="$_t('requestDetail.gender')" 
              :subTitle="form.model.gender ? form.model.gender.name : ''"
            />
            <request-details-information
              :title="$_t('requestDetail.totalJob')"
              :subTitle="form.model.projectCount"
            />
            <request-details-information
              v-if="userType === 'manager'"
              title="PHONE NUMBER"
              :subTitle="form.model.cell_phone"
            />
            <li class="mt-1 more" v-if="userType === 'manager'">
              <router-link
                :to="{
                  name: 'modelDetailRequest',
                  params: { id: form.model.id, locale: $_getlocaleParam() },
                }"
              >
                <block-button
                  text="KNOW MORE"
                  icon-right="WMi-right-open"
                  class="btn-more site__button width-auto"
                  @click.native="$_closeModal()"
                ></block-button>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="px-0 px-md-3 pt-4 d-flex justify-center justify-md-end w-100">
        <block-button
          class="btn-allright site__button width-auto my-0"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../Global/Section/RequestDetailsInformation.vue";
import { UserStorage } from "@/utils/storage";
export default {
  name: "modal_requestDetails",
  data() {
    return {
      form: {
        user: {},
        model: {
          gender: {},
        },
        jobType: {},
      },
      userType: UserStorage.get() ? UserStorage.get().type : "client",
    };
  },
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  methods: {
    openModal({ model }) {
      if (model) {
        this.form = { ...model };
      }
    },
  },
};
</script>
<style scoped>
.requester__modal__list {
  margin-bottom: 45px;
}

.btn-more {
  font-family: "Barlow-EL", sans-serif;
  font-size: 11px;
  padding: 13px 10px !important;
}
.btn-allright {
  font-size: 14px;
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  position: relative;
  top: -0.6rem;
  cursor: default !important;
  letter-spacing: 3px;
  margin: 10px 20px;
}
.more {
  position: absolute;
  right: 3px;
}
.more a {
  text-decoration: none;
}

.request__details__information {
  display: flex;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.4rem;
}
.request__details__information--sub-title a {
  color: var(--color-black);
}
.request__details__information--sub-title--block {
  font-family: "Montserrat-regular";
}
@media screen and (max-width: 480px) {
  .btn-allright {
    font-size: 10px !important;
    margin: 0;
  }
}
</style>
