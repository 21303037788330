<template>
  <div class="wa__model__request mb-1">
    <v-row class="text-left">
      <v-col
        cols="6"
        sm="4"
        class="wa__model__request__name"
        @click="$_openModal('requestDetails', { model: request })"
      >
        <name :number="index + 1" :title="request.model.first_name" />
      </v-col>
      <!-- date-status section-->
      <v-col cols="4" class="d-none d-sm-block wa__model__request__date">
        <div class="h-100 d-flex align-center justify-center">
          <div>
            <!-- /date button-->
            <block-button
              height="13"
              class="btn-date"
              color="gray"
              bg-color="bgGray"
              :text="request.created_at"
            />
            <!-- /date button-->
            <!-- status button-->
            <div>
              <block-button
                height="auto"
                class="btn-pending"
                :text="request.status.name"
                @click.native="
                  type === 'manager'
                    ? $_openModal('changeStatus', { model: request })
                    : () => {}
                "
                :color="request.status.style.color"
                :bg-color="request.status.style.bgColor"
              ></block-button>
            </div>
            <!-- /status button-->
          </div>
        </div>
      </v-col>
      <!-- /date-status section-->
      <!-- control section-->
      <v-col
        cols="6"
        sm="4"
        class="d-flex justify-content-end align-items-center"
      >
        <!-- align button-->
        <circle-button
          icon="WMi-align-left"
          color="black"
          @click.native="$_openModal('requestDetails', { model: request })"
        ></circle-button>
        <!-- /align button-->
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
import Name from "../Global/Section/Name.vue";
import { mapActions } from "vuex";
import { UserStorage } from "@/utils/storage";
export default {
  components: {
    Name,
  },
  data() {
    return {
      type: UserStorage.get().type,
    };
  },
  methods: {
    openModal() {},
    ...mapActions("request", ["deleteRequest"]),
  },
  props: {
    request: {
      type: Object,
    },
    index: {},
  },
};
</script>
<style scoped>
.wa__model__request {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 7px 10px;
}

.wa__model__request__name {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.wa__model__request__name--title {
  font-family: "Montserrat-regular", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}
.btn-operations {
  font-size: 8px;
  position: relative;
  top: -0.6rem;
  cursor: default !important;
  padding: 3px 5px !important;
  border-radius: 1px !important;
}
.btn-operations--only {
  font-size: 16px;
  top: -0.4rem;
  padding: 3px 12px !important;
}

.btn-date {
  padding: 0 15px !important;
  font-size: 9px;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  cursor: default !important;
}
.btn-pending {
  font-family: "Montserrat-bold";
  font-size: 14px;
  font-weight: 900;
  padding: 1px 10px !important;
  position: relative;
  top: -0.2rem;
  border-radius: 1px !important;
}
.request__detail li {
  display: inline-flex;
  margin-right: 65px;
}
.request__detail li:nth-child(6) {
  margin-right: 140px;
}
</style>
