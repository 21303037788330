<template>
  <div class="d-flex align-center h-100">
    <template v-if="number">
      <div class="wa__model__request__name--number">
        {{ number }}
      </div>
    </template>
    <div style="line-height: 12px">
      <div
        :class="{
          'wa__model__request__name--name': !variable,
          'wa__model__request__name--name--only': variable,
        }"
      >
        {{ title }}
      </div>
      <small class="wa__model__request__name--email" v-if="subTitle">{{
        subTitle
      }}</small>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    number: {},
    title: {},
    subTitle: {},
    variable: {
      default: false,
    },
  },
};
</script>
<style scoped>
.wa__model__request__name--number {
  font-family: "Barlow-EL", sans-serif;
  font-size: 33px;
  padding: 0 5px 4px 0;
  color: var(--color-black);
}
.wa__model__request__name--name {
  font-family: "Montserrat-regular";
  font-size: 17px;
  /* padding-top: 5px; */
}
.wa__model__request__name--name--only {
  padding-top: 0 !important;
  font-family: "Montserrat-regular";
  font-size: 17px;
  margin-top: -6px;
}
.wa__model__request__name--email {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-smoky);
}
.message__sender .wa__model__request__name--name {
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  .wa__model__request__name--number {
    font-size: 23px;
  }
  .wa__model__request__name--name {
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
  }
  .wa__model__request__name--email {
    font-size: 9px;
    top: -0.8rem;
  }
}
</style>
